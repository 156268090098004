import React from "react";
import { Helmet } from "react-helmet";
import NavigationBar from "../../../components/navigation";
import { getSavedUserPropertyFromLocalStorage } from "../../../util/utils";
import Footer from "../../../components/footer";
import LoginModal from "../../../components/loginModal";
import ClickableCards from "./clickableCards";

/**
 * Inspirations: Bootstrap classes buildups inspired from documentation:
 * - https://getbootstrap.com/docs/5.3/components/card/, visited 20-10-23.
 * - https://getbootstrap.com/docs/5.3/components/, visited 20-10-23.
 *
 * @returns The home page when the user is logged in (Non business)
 */

function Home() {
  return (
    <div>
      <div className="container-fluid">
        <Helmet>
          <title>FindDyrlæge | Hjem</title>
        </Helmet>
        <div className="row">
          <div className="col-md-2">
            <NavigationBar />
          </div>
          <div className="col-md-10">
            <div className="d-flex justify-content-between mb-4">
              <div />
              <div>
                <LoginModal />
              </div>
            </div>
            <div className="col-md-10">
              <div className="card">
                <div className="card-body">
                  <h3 className="card-left">
                    Velkommen, {getSavedUserPropertyFromLocalStorage("name")}!
                  </h3>
                  <p className="card-text ">Klik i menuen for at komme igang</p>
                  <br />
                </div>
              </div>
              <ClickableCards />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Home;

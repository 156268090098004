import Footer from "../../../components/footer";
import NavigationBar from "../../../components/navigation";
import { Helmet } from "react-helmet";
import {
  capitalizeFirstLetter,
  doesUserEmailExistInDatabase,
  getSavedUserPropertyFromLocalStorage,
  saveUserToLocalStorage,
} from "../../../util/utils";
import LoginModal from "../../../components/loginModal";
import React, { useState } from "react";
import axios from "axios";

/**
 * Inspirations: Bootstrap classes buildups inspired from documentation:
 * - https://getbootstrap.com/docs/5.3/components/card/, visited 20-10-23.
 * - https://getbootstrap.com/docs/5.3/components/, visited 20-10-23.
 *
 *
 * @returns The page where business users can change their profile information
 */

function BusinessProfile() {
  const [name, setName] = useState(
    getSavedUserPropertyFromLocalStorage("name")
  );
  const [phoneNumber, setPhoneNumber] = useState(
    getSavedUserPropertyFromLocalStorage("phoneNumber")
  );
  const [roadNameAndNumber, setRoadNameAndNumber] = useState(
    getSavedUserPropertyFromLocalStorage("roadNameAndNumber")
  );
  const [cityName, setCityName] = useState(
    getSavedUserPropertyFromLocalStorage("cityName")
  );
  const [zipCode, setZipCode] = useState(
    getSavedUserPropertyFromLocalStorage("zipCode")
  );
  const [area, setArea] = useState(
    getSavedUserPropertyFromLocalStorage("geographicalArea")
  );
  const [email, setEmail] = useState(
    getSavedUserPropertyFromLocalStorage("email")
  );
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");
  const [inputboxChecked, setInputboxChecked] = React.useState(false);

  const handleInputboxChange = () => {
    setInputboxChecked(!inputboxChecked);
  };

  const handlerChangeEvent_name = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
    //console.log("Name value is: " + name);
  };

  const handlerChangeEvent_phoneNumber = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPhoneNumber(e.target.value);
    //console.log("PhoneNumber value is: " + phoneNumber);
  };

  const handlerChangeEvent_roadNameAndNumber = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRoadNameAndNumber(e.target.value);
    //console.log("roadNameAndNumber value is: " + roadNameAndNumber);
  };

  const handlerChangeEvent_cityName = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCityName(e.target.value);
    //console.log("cityName value is: " + cityName);
  };

  const handlerChangeEvent_zipCode = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setZipCode(e.target.value);
    //console.log("zipCode value is: " + zipCode);
  };

  const handlerChangeEvent_area = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setArea(e.target.value);
    //console.log("area value is: " + area)
  };

  const handlerChangeEvent_email = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    //console.log("email value is: " + email);
  };

  const handlerChangeEvent_oldPassword = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setOldPassword(e.target.value);
    //console.log("oldPassword value is: " + oldPassword);
  };

  const handlerChangeEvent_newPassword = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNewPassword(e.target.value);
    //console.log("newPassword value is: " + newPassword);
  };

  const handlerChangeEvent_newPasswordConfirm = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNewPasswordConfirm(e.target.value);
    //console.log("newPasswordConfirm value is: " + newPasswordConfirm);
  };

  /* Inspiration on how to make the function async: https://www.codecademy.com/learn/asynchronous-javascript/modules/asynch-js/cheatsheet, visited 30-10-23. */
  const submitForm = async () => {
    //async because we need to check first if user exists, and depending on that answer create the new user or not
    if (
      //check input fields are not empty
      name === "" ||
      phoneNumber === "" ||
      roadNameAndNumber === "" ||
      cityName === "" ||
      zipCode === "" ||
      area === "" ||
      email === ""
    ) {
      alert("Venligst udfyld alle felter");
      return;
    }

    if (inputboxChecked) {
      //checked if pw change wanted
      if (oldPassword !== getSavedUserPropertyFromLocalStorage("password")) {
        //if old password not correct
        alert("Gamle kodeord ej korrekt");
        return;
      }

      if (newPassword !== newPasswordConfirm) {
        // if new passwords do not match
        alert("Nye kodeord er ej ens");
        return;
      }
    }

    const attemptedUserAlreadyExists = await doesUserEmailExistInDatabase(
      email
    );
    if (!attemptedUserAlreadyExists) {
      // user does not already exist
      postEditedUser();
    } else {
      if (email === getSavedUserPropertyFromLocalStorage("email")) {
        //email is the same as before, so just update the user
        postEditedUser();
      } else {
        //user already exists
        alert("Der findes allerede en bruger med denne email");
      }
    }
  };

  const postEditedUser = () => {
    var object: object = {};
    if (inputboxChecked) {
      //if password change wanted
      object = {
        id: getSavedUserPropertyFromLocalStorage("id"),
        isBusinessUser: getSavedUserPropertyFromLocalStorage("isBusinessUser"),
        name: capitalizeFirstLetter(name),
        phoneNumber: phoneNumber,
        roadNameAndNumber: capitalizeFirstLetter(roadNameAndNumber),
        cityName: capitalizeFirstLetter(cityName),
        zipCode: zipCode,
        geographicalArea: area,
        email: email,
        password: newPassword,
      };
    } else if (!inputboxChecked) {
      //if password change not wanted
      object = {
        id: getSavedUserPropertyFromLocalStorage("id"),
        isBusinessUser: getSavedUserPropertyFromLocalStorage("isBusinessUser"),
        name: capitalizeFirstLetter(name),
        phoneNumber: phoneNumber,
        roadNameAndNumber: capitalizeFirstLetter(roadNameAndNumber),
        cityName: capitalizeFirstLetter(cityName),
        zipCode: zipCode,
        geographicalArea: area,
        email: email,
        password: getSavedUserPropertyFromLocalStorage("password"),
      };
    }

    const url =
      "https://localhost:7083/api/Users/" +
      getSavedUserPropertyFromLocalStorage("id");
    axios
      .put(url, object)
      .then((response) => {
        console.log(response.data);
        saveUserToLocalStorage(object);
        alert("Oplysninger ændret");
        window.location.reload();
      })
      .catch(function (error) {
        if (error.response) {
          // Error occured
          console.log(error.response.status);
          console.log(error.response.data.title);
          console.log(error.response.data);
          alert(
            "Der er sket en fejl. Tjek alle felter er udfyldt korrekt og prøv igen"
          );
        }
      });
  };

  return (
    <div>
      <div className="container-fluid">
        <Helmet>
          <title>FindDyrlæge | Profil</title>
        </Helmet>
        <div className="row">
          <div className="col-md-2">
            <NavigationBar />
          </div>
          <div className="col-md-10">
            <div className="d-flex justify-content-between mb-4">
              <div />
              <div>
                <LoginModal />
              </div>
            </div>
            <div className="col-md-10">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">
                    Her kan I ændre jeres oplysninger:
                  </h5>
                </div>
                <div className="card-body">
                  {/* Class styles (classNames) inspiration from LoginModal component*/}
                  <form>
                    <div className="form-containerspacing">
                      <label>Firmanavn</label>
                      <input
                        type="text"
                        className="form-control"
                        id="inputName"
                        placeholder="Indtast"
                        value={name}
                        onChange={handlerChangeEvent_name}
                      />
                    </div>
                    <div className="form-containerspacing">
                      <label>Telefonnummer</label>
                      <input
                        type="number"
                        className="form-control"
                        id="inputPhoneNo"
                        placeholder="Indtast"
                        value={phoneNumber}
                        onChange={handlerChangeEvent_phoneNumber}
                      />
                    </div>
                    <div className="form-containerspacing">
                      <label>Vejnavn og nummer</label>
                      <input
                        type="text"
                        className="form-control"
                        id="inputRoadAndNo"
                        placeholder="Indtast"
                        value={roadNameAndNumber}
                        onChange={handlerChangeEvent_roadNameAndNumber}
                      />
                    </div>
                    <div className="form-containerspacing">
                      <label>Bynavn</label>
                      <input
                        type="text"
                        className="form-control"
                        id="inputCityName"
                        placeholder="Indtast"
                        value={cityName}
                        onChange={handlerChangeEvent_cityName}
                      />
                    </div>
                    <div className="form-containerspacing">
                      <label>Postnummer</label>
                      <input
                        type="number"
                        className="form-control"
                        id="inputZipCode"
                        placeholder="Indtast"
                        value={zipCode}
                        onChange={handlerChangeEvent_zipCode}
                      />
                    </div>
                    <div className="form-containerspacing">
                      <label>Område</label>
                      <select
                        value={area}
                        onChange={handlerChangeEvent_area}
                        className="form-select"
                        id="selectArea"
                      >
                        <option selected>Vælg område</option>
                        <option value="Sjælland">Sjælland</option>
                        <option value="Fyn">Fyn</option>
                        <option value="Jylland">Jylland</option>
                      </select>
                    </div>
                    <div className="form-containerspacing">
                      <label>E-mail</label>
                      <input
                        placeholder="Indtast e-mail"
                        id="loginEmail"
                        className="form-control"
                        type="email"
                        value={email}
                        onChange={handlerChangeEvent_email}
                      />
                    </div>
                    {/* Form Check div inspired from: https://getbootstrap.com/docs/5.3/forms/checks-radios/, visited 20-10-23. */}
                    <div className="form-check mt-2">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                        checked={inputboxChecked}
                        onChange={handleInputboxChange}
                      />
                      <label className="form-check-label">Skift password</label>
                    </div>
                    {inputboxChecked && (
                      <div>
                        <div className="form-containerspacing">
                          <label>Gamle kodeord</label>
                          <input
                            placeholder="Indtast gamle kodeord"
                            id="loginPasswordOld"
                            className="form-control"
                            type="password"
                            onChange={handlerChangeEvent_oldPassword}
                          />
                        </div>
                        <div className="form-containerspacing">
                          <label>Nyt kodeord</label>
                          <input
                            placeholder="Indtast nyt kodeord"
                            id="loginPassword"
                            className="form-control"
                            type="password"
                            onChange={handlerChangeEvent_newPassword}
                          />
                        </div>
                        <div className="form-containerspacing">
                          <label>Bekræft nyt kodeord</label>
                          <input
                            placeholder="Indtast nyt kodeord"
                            id="loginPasswordConfirm"
                            className="form-control"
                            type="password"
                            onChange={handlerChangeEvent_newPasswordConfirm}
                          />
                        </div>
                      </div>
                    )}
                    <div className="loginBtn">
                      <button
                        onClick={submitForm}
                        type="button"
                        className="btn btn-primary"
                      >
                        Gem
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default BusinessProfile;

import React from "react";
import Footer from "../../../components/footer";
import NavigationBar from "../../../components/navigation";
import { Helmet } from "react-helmet";
import {
  getCorrectDateAndTime,
  getSavedUserPropertyFromLocalStorage,
} from "../../../util/utils";
import LoginModal from "../../../components/loginModal";
import axios from "axios";
import { useState } from "react";

/**
 * Inspirations: Bootstrap classes buildups inspired from documentation:
 * - https://getbootstrap.com/docs/5.3/components/card/, visited 20-10-23.
 * - https://getbootstrap.com/docs/5.3/components/, visited 20-10-23.
 * - https://getbootstrap.com/docs/5.3/forms/select/, visited 20-10-23.
 *
 *
 * @returns The page where the user can create a new task
 */

function UserTaskNew() {
  const [race, setRace] = useState("");
  const [service, setService] = useState("");
  const [name, setName] = useState("");
  const [area, setArea] = useState("");
  const [birthdate, setBirthdate] = useState("");

  const handlerChangeEvent_race = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setRace(e.target.value);
    //console.log("race value is: " + race)
  };

  const handlerChangeEvent_service = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setService(e.target.value);
    //console.log("service value is: " + service)
  };

  const handlerChangeEvent_name = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
    //console.log("Name value is: " + name)
  };

  const handlerChangeEvent_area = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setArea(e.target.value);
    //console.log("area value is: " + area)
  };

  const handlerChangeEvent_birthdate = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setBirthdate(e.target.value);
    //console.log("birthdate value is: " + birthdate)
  };

  const submitForm = () => {
    //are all fields filled out?
    if (
      name === "" ||
      service === "" ||
      name === "" ||
      area === "" ||
      birthdate === ""
    ) {
      alert("Venligst udfyld alle felter");
    } else {
      postTask();
    }
  };

  const postTask = () => {
    var object = {
      userId: getSavedUserPropertyFromLocalStorage("id").toString(), //id of currently logged in user
      animalRace: race,
      wantedService: service,
      animalName: name,
      areaOfInterest: area,
      animalBirthdate: birthdate + "T00:00:00.000Z",
      status: "Oprettet", //default status when creating new task
      creationDate: getCorrectDateAndTime(),
    };

    axios
      .post("https://localhost:7083/api/Tasks", object)
      .then((response) => {
        console.log(response.data);
        window.location.href =
          "/privat/mine-sager/" +
          response.data.id /* navigate to the newly created task id */;
      })
      .catch(function (error) {
        if (error.response) {
          // Error occured
          console.log(error.response.status);
          console.log(error.response.data.title);
          console.log(error.response.data);
          alert(
            "Der er sket en fejl. Tjek alle felter er udfyldt korrekt og prøv igen"
          );
        }
      });
  };

  return (
    <div>
      <div className="container-fluid">
        <Helmet>
          <title>FindDyrlæge | Opret</title>
        </Helmet>
        <div className="row">
          <div className="col-md-2">
            <NavigationBar />
          </div>
          <div className="col-md-10">
            <div className="d-flex justify-content-between mb-4">
              <div />
              <div>
                <LoginModal />
              </div>
            </div>
            {/* Class styles (classNames) inspiration from LoginModal component*/}
            <div className="col-md-10">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">Opret sag</h5>
                </div>
                <div className="card-body">
                  <form>
                    <div className="form-containerspacing">
                      <label>Dyrets race</label>
                      <select
                        onChange={handlerChangeEvent_race}
                        className="form-select"
                        id="selectAnimalRace"
                      >
                        <option selected>Vælg dyr</option>
                        <option value="Hund">Hund</option>
                        <option value="Kat">Kat</option>
                      </select>
                    </div>
                    <div className="form-containerspacing">
                      <label>Ønsket ydelse</label>
                      <select
                        onChange={handlerChangeEvent_service}
                        className="form-select"
                        id="selectService"
                      >
                        <option selected>Vælg ydelse</option>
                        <option value="Konsultation">Konsultation</option>
                        <option value="Vaccination">Vaccination</option>
                        <option value="Sterilisation">Sterilisation</option>
                      </select>
                    </div>
                    <div className="form-containerspacing">
                      <label>Dyrets navn</label>
                      <input
                        type="text"
                        className="form-control"
                        id="inputAnimalName"
                        placeholder="Indtast"
                        onChange={handlerChangeEvent_name}
                      />
                    </div>
                    <div className="form-containerspacing">
                      <label>Område</label>
                      <select
                        onChange={handlerChangeEvent_area}
                        className="form-select"
                        id="selectArea"
                      >
                        <option selected>Vælg område</option>
                        <option value="Sjælland">Sjælland</option>
                        <option value="Fyn">Fyn</option>
                        <option value="Jylland">Jylland</option>
                      </select>
                    </div>
                    <div className="form-containerspacing">
                      <label>Dyr fødselsdato</label>
                      <input
                        type="date"
                        className="form-control"
                        id="birthdateAnimal"
                        onChange={handlerChangeEvent_birthdate}
                      />
                    </div>
                    <div className="loginBtn">
                      <button
                        onClick={submitForm}
                        type="button"
                        className="btn btn-primary mt-2"
                      >
                        Gem
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default UserTaskNew;

import React from "react";
import { Helmet } from "react-helmet";
import NavigationBar from "../../../components/navigation";
import Footer from "../../../components/footer";
import LoginModal from "../../../components/loginModal";

/**
 * Inspirations: Bootstrap classes buildups inspired from documentation:
 * - https://getbootstrap.com/docs/5.3/components/card/, visited 20-10-23.
 * - https://getbootstrap.com/docs/5.3/components/, visited 20-10-23.
 *
 *
 * @returns The page where there are references and recommendations from previous customers
 */

function References() {
  return (
    <div>
      <div className="container-fluid">
        <Helmet>
          <title>FindDyrlæge | Referencer</title>
        </Helmet>
        <div className="row">
          <div className="col-md-2">
            <NavigationBar />
          </div>
          <div className="col-md-10">
            <div className="d-flex justify-content-between mb-4">
              <div />
              <div>
                <LoginModal />
              </div>
            </div>
            <div className="col-md-10">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">
                    Feedback fra nogle af vores kunder
                  </h5>
                </div>
                <div className="card-body">
                  <h3 className="card-left">Michael og hans hund, 2 år</h3>
                  <p className="card-text">
                    Jeg er simpelthen så glad for den service jeg fik, da min
                    hund skulle steriliseres. Jeg er så tilfreds og vil bruge
                    FindDyrlæge fremover.
                  </p>

                  <br />

                  <h3 className="card-left">Jan og hendes kat, 5 år</h3>
                  <p className="card-text">
                    Jeg synes altid at jeg har betalt for meget for mine
                    dyrlægeydelser, men så fandt jeg FindDyrlæge. Her fandt jeg
                    ud af at der faktisk var en dyrlæge tæt på mig, som jeg slet
                    ikke vidste eksisterede. De har fantastisk service og endnu
                    bedre priser end jeg er vant til.
                  </p>

                  <br />
                  <h3 className="card-left">Jim og hans kat, 1 år</h3>
                  <p className="card-text">
                    Det hele fungerede super godt. Jeg fik hurtigt svar på min
                    anmodning og fik en super god pris.
                  </p>
                  <br />

                  <h3 className="card-left">Andy og hans hund, 2 år</h3>
                  <p className="card-text">
                    Jeg har nu brugt FindDyrlæge flere gange til flere af mine
                    dyr og det fungerer altid bare SÅ godt.
                  </p>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default References;

import React from "react";
import { useNavigatePath } from "../../../../util/utils";

const ClickableCards = () => {
  const toTasks = useNavigatePath("/erhverv/sager");
  const toHistoricTasks = useNavigatePath("/erhverv/sager-historik");
  const toPrices = useNavigatePath("/erhverv/priser");

  /*
  Component to be used to show clickable cards on the user home page
  */
  return (
    <div className="container">
      <div className="row">
        <div onClick={toTasks} className="col-4">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Se sager</h5>
              <p className="card-text">
                Klik her for at se alle aktuelle sager, afgive tilbud og se
                status
              </p>
            </div>
          </div>
        </div>
        <div onClick={toHistoricTasks} className="col-4">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Se historiske sager</h5>
              <p className="card-text">
                Klik her for at se sager afsluttet for mere end 4 uger siden
              </p>
            </div>
          </div>
        </div>
        <div onClick={toPrices} className="col-4">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Ret klinikkens priser</h5>
              <p className="card-text">
                Klik her for at se og rette klinikkens priser til brug for
                tilbudsgivning
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClickableCards;

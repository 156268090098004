import React from "react";
import { Helmet } from "react-helmet";
import NavigationBar from "../../../components/navigation";
import Footer from "../../../components/footer";
import LoginModal from "../../../components/loginModal";

/**
 * Inspirations: Bootstrap classes buildups inspired from documentation:
 * - https://getbootstrap.com/docs/5.3/components/card/, visited 20-10-23.
 *
 *
 * @returns The page which has the terms and conditions for using the website
 */

function TermsAndConditions() {
  return (
    <div>
      <div className="container-fluid">
        <Helmet>
          <title>FindDyrlæge | Vilkår</title>
        </Helmet>
        <div className="row">
          <div className="col-md-2">
            <NavigationBar />
          </div>
          <div className="col-md-10">
            <div className="d-flex justify-content-between mb-4">
              <div />
              <div>
                <LoginModal />
              </div>
            </div>
            <div className="col-md-10">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">Vilkår</h5>
                </div>
                <div className="card-body">
                  <h2 className="card-left">Generelle vilkår</h2>
                  <p className="card-text">
                    Ved at bruge hjemmesiden accepterer du vores vilkår.
                  </p>
                  <br />

                  <h2 className="card-left">Privatlivspolitik</h2>
                  <p className="card-text">
                    Ved at bruge hjemmesiden accepterer du privatlivspolitikken.
                  </p>
                  <br />

                  <h2 className="card-left">Cookiepolitik</h2>
                  <p className="card-text">
                    Ved at bruge hjemmesiden accepterer du at vi potentielt
                    bruger cookies.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default TermsAndConditions;

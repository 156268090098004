import React from "react";
import animalsatvetcrop from "./../../../../resources/images/animalsAtVetcrop.png";
import animalsatvetcrop_smallWidth from "./../../../../resources/images/animalsAtVetcrop_half.png";
import faq from "./../../../../resources/images/faq.png";
import allAnimals from "./../../../../resources/images/allAnimals.png";
import happyPerson from "./../../../../resources/images/happyPerson.png";
import "./style.scss";
import { useNavigatePath } from "./../../../../util/utils";

/*
Component to be used on frontpage to show images and text

Inspiration: 
Bootstrap classes buildups inspired from documentation: 
- https://getbootstrap.com/docs/5.3/components/card/, visited 20-10-23.
- https://getbootstrap.com/docs/5.3/components/, visited 20-10-23.

ClassName classes inspirations: 
- https://bootstrapshuffle.com/classes/cards/card-title, visited 20-10-23.
*/

const ImagesComponent = () => {
  const toSignup = useNavigatePath("/privat/opret");
  const toBusinessHome = useNavigatePath("/erhverv/hjem");
  const toUserTaskNew = useNavigatePath("/privat/opret-sag");
  const toFaq = useNavigatePath("/faq");

  const getStarted = () => {
    var getUser = localStorage.getItem("user"); //get the user from local storage

    if (getUser != null) {
      //check if user is logged in
      if (JSON.parse(getUser).isBusinessUser) {
        //business user
        toBusinessHome();
      } else {
        //private user
        toUserTaskNew();
      }
    } else {
      // not signed in
      toSignup();
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="header-container headerPadding">
            <h2>Velkommen til FindDyrlæge</h2>
            <h2>Start med at spare penge på dyrlæge besøg allerede i dag</h2>
          </div>
        </div>
        <div className="col-12 marginImages">
          <button
            onClick={getStarted}
            type="submit"
            className="btn btn-primary me-2"
          >
            Klik for at komme i gang
          </button>
        </div>
      </div>

      {window.innerWidth > 1000 ? ( //check if screen width is bigger than 1000px, if then show wide image, else show less wide image
        <div className="row">
          <div className="col-12">
            <img
              src={animalsatvetcrop}
              className="full-width-image marginImages"
              alt="animals at the vet"
            />
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col-12">
            <img
              src={animalsatvetcrop_smallWidth}
              className="full-width-image marginImages"
              alt="animals at the vet small width format"
            />
          </div>
        </div>
      )}

      <div className="row">
        <div onClick={getStarted} className="col-4">
          <div className="card">
            <img src={happyPerson} className="card-img" alt="happy man" />
            <div className="card-body">
              <h5 className="card-title">Tilfredse kunder</h5>
              <p className="card-text">
                Vi har allerede hjulpet 300+ med at spare penge på deres
                dyrlægeregning
              </p>
            </div>
          </div>
        </div>
        <div onClick={getStarted} className="col-4">
          <div className="card">
            <img src={allAnimals} className="card-img" alt="three animals" />
            <div className="card-body">
              <h5 className="card-title">Mange racer</h5>
              <p className="card-text">
                Vi kan hjælpe langt de fleste dyreracer med det de har brug for
              </p>
            </div>
          </div>
        </div>
        <div onClick={toFaq} className="col-4">
          <div className="card">
            <img src={faq} className="card-img" alt="faq sign" />
            <div className="card-body">
              <h5 className="card-title">FAQ</h5>
              <p className="card-text">
                Se svar på oftest stillede spørgsmål ved at trykke på
                menupunktet
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImagesComponent;

import React from 'react';
import { useNavigatePath } from "../../util/utils";

const Footer = () => {
  const toTerms = useNavigatePath("/vilkaar");

  /*
  Component to be shown on the bottom of pages 

  Inspiration: https://mdbootstrap.com/docs/standard/navigation/footer/, visited 20-10-23.
  */
  return (
    <footer className="bg-dark text-center p-3">
      <div className="col-md-center text-white">
        <p> FindDyrlæge {new Date().getFullYear()}</p>
        <u>
          <p onClick={toTerms}> Vilkår</p>
        </u>
      </div>
    </footer>
  );
};

export default Footer;

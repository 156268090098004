import React from "react";
import { Helmet } from "react-helmet";
import NavigationBar from "../../../components/navigation";
import {
  convertDateToDkFormat,
  getCorrectDateAndTime,
  useNavigatePath,
} from "../../../util/utils";
import Footer from "../../../components/footer";
import LoginModal from "../../../components/loginModal";
import { useEffect, useState } from "react";
import axios from "axios";
import { ITasks, IUser } from "../../../util/interfaces";
import moment from "moment";

/**
 * Inspiration: Bootstrap classes buildups inspired from documentation:
 * - https://getbootstrap.com/docs/5.3/components/card/, visited 20-10-23.
 * - https://getbootstrap.com/docs/5.3/components/, visited 20-10-23.
 *
 *
 * @returns The page where the private user sees a specific task id
 */

function SpecificTaskUser() {
  const toMyTasks = useNavigatePath("/privat/mine-sager");

  const getTaskIdNumber = window.location.pathname.replace(
    "/privat/mine-sager/",
    ""
  );
  const [myTask, setMyTask] = useState<ITasks>();
  const [
    userInfoFromOfferingBusinessUser,
    setUserInfoFromOfferingBusinessUser,
  ] = useState<IUser>();

  const acceptOffer = () => {
    //change status and put axios request
    updateTask("accepteret");
  };

  const declineOffer = () => {
    //change status and put axios request
    updateTask("afvist");
  };

  /* Updates the task when the user responds to an offer */
  const updateTask = (answer: string) => {
    var object = {
      id: getTaskIdNumber,
      userId: myTask?.userId,
      businessUserId: myTask?.businessUserId,
      wantedService: myTask?.wantedService,
      animalRace: myTask?.animalRace,
      animalName: myTask?.animalName,
      userAnswer: answer,
      requiredMedicineName: myTask?.requiredMedicineName,
      requiredMedicinePrice: myTask?.requiredMedicinePrice,
      areaOfInterest: myTask?.areaOfInterest,
      status: "Afsluttet",
      animalBirthdate: myTask?.animalBirthdate,
      creationDate: myTask?.creationDate,
      endDate: getCorrectDateAndTime(),
      offerDate: myTask?.offerDate,
      descriptionFromVet: myTask?.descriptionFromVet,
      price: myTask?.price,
    };

    const url = "https://localhost:7083/api/Tasks/" + getTaskIdNumber;
    axios
      .put(url, object)
      .then((response) => {
        console.log(response.data);
        window.location.reload();
      })
      .catch(function (error) {
        if (error.response) {
          // Error occured
          console.log(error.response.status);
          console.log(error.response.data.title);
          console.log(error.response.data);
          alert("Der er sket en fejl. Prøv igen");
        }
      });
  };

  /* Gets the task for the user */
  const getTask = () => {
    const url = "https://localhost:7083/api/Tasks/" + getTaskIdNumber;
    axios
      .get(url)
      .then((response) => {
        console.log(response.data);
        setMyTask(response.data);
      })
      .catch(function (error) {
        if (error.response) {
          // Error occured
          console.log(error.response.status);
          console.log(error.response.data.title);
          console.log(error.response.data);
          alert("Der er sket en fejl. Prøv igen");
        }
      });
  };

  /* Gets info about business user from their id */
  const getUserFromId = () => {
    if (
      //do nothing if the user id is not gotten yet
      userInfoFromOfferingBusinessUser?.name === undefined ||
      userInfoFromOfferingBusinessUser?.name === null ||
      userInfoFromOfferingBusinessUser?.name === ""
    ) {
      axios
        .get("https://localhost:7083/api/Users/" + myTask?.businessUserId)
        .then((response) => {
          console.log(response.data);
          setUserInfoFromOfferingBusinessUser(response.data);
        })
        .catch(function (error) {
          if (error.response) {
            // Error occured
            console.log(error.response.status);
            console.log(error.response.data.title);
            console.log(error.response.data);
          }
        });
    }
  };

  useEffect(() => {
    getTask(); //gets the task info
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUserFromId()]); //gets the business user info once user id is gotten

  return (
    <div>
      <div className="container-fluid">
        <Helmet>
          <title>FindDyrlæge | Sag</title>
        </Helmet>
        <div className="row">
          <div className="col-md-2">
            <NavigationBar />
          </div>
          <div className="col-md-10">
            <div className="d-flex justify-content-between mb-4">
              <div />
              <div>
                <LoginModal />
              </div>
            </div>
            <div className="col-md-10">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">Sag id {getTaskIdNumber}</h5>
                </div>
                <div className="card-body">
                  <h3 className="card-left text-center">
                    Oprettet:{" "}
                    {convertDateToDkFormat(myTask?.creationDate || "")} - Dyr:{" "}
                    {myTask?.animalName}, {myTask?.animalRace},{" "}
                    {moment().diff(myTask?.animalBirthdate, "years")} år{" "}
                    {/* Inspiration for how to use MomentJS library to calculate age from date: https://medium.com/calculatte-age-from-date-of-birth-in-typescript/calculatte-age-from-date-of-birth-43357a74e503, visited 20-10-23. */}{" "}
                    - {myTask?.wantedService}, {myTask?.areaOfInterest} -
                    Status: {myTask?.status}
                  </h3>
                  <p className="card-text">
                    {myTask?.descriptionFromVet
                      ? "Tilbud modtaget"
                      : "Afventer tilbud fra dyrlæge"}
                  </p>
                  <br />
                  {myTask?.descriptionFromVet == null || "" ? (
                    <div />
                  ) : (
                    <div>
                      {/* Class styles (classNames) inspiration from LoginModal component*/}
                      <div className="card">
                        <h5>Tilbud fra klinikken:</h5>
                        <p>{myTask?.price} kr.</p>
                        <h5>Beskrivelse fra klinikken:</h5>
                        <p>{myTask?.descriptionFromVet}</p>
                        {myTask?.businessUserId !== "" ||
                        myTask?.businessUserId !== null ? ( // only show business user info, if an offer has been made
                          <div>
                            <h5>Klinik oplysninger:</h5>
                            <p className="card-text text-center">
                              Navn: {userInfoFromOfferingBusinessUser?.name}.{" "}
                              <br />
                              Tlf. nr.: +45{" "}
                              {
                                userInfoFromOfferingBusinessUser?.phoneNumber
                              }. <br />
                              E-mail: {
                                userInfoFromOfferingBusinessUser?.email
                              }. <br />
                              Adresse:{" "}
                              {
                                userInfoFromOfferingBusinessUser?.roadNameAndNumber
                              }
                              . <br />
                              By: {
                                userInfoFromOfferingBusinessUser?.cityName
                              }, {userInfoFromOfferingBusinessUser?.zipCode}.{" "}
                              <br />
                            </p>
                          </div>
                        ) : (
                          <div></div>
                        )}
                      </div>
                      <div>
                        {myTask?.userAnswer == null || "" ? (
                          <div>
                            {" "}
                            <button
                              onClick={acceptOffer}
                              type="button"
                              className="btn btn-success mt-2"
                            >
                              Accepter tilbud
                            </button>
                            <button
                              onClick={declineOffer}
                              type="button"
                              className="btn btn-danger mt-2"
                            >
                              Afvis tilbud
                            </button>
                            <button
                              onClick={toMyTasks}
                              type="button"
                              className="btn btn-secondary mt-2"
                            >
                              Tilbage
                            </button>
                          </div>
                        ) : (
                          <div>
                            <h4 className="card-left text-center">
                              {" "}
                              Tilbud {myTask?.userAnswer} d.{" "}
                              {convertDateToDkFormat(myTask?.endDate)}{" "}
                            </h4>

                            {myTask?.userAnswer === "accepteret" ? (
                              <div>
                                <p className="card-text text-center">
                                  Du bedes kontakte klinikken senest 14 dage
                                  efter svar på tilbuddet
                                </p>
                              </div>
                            ) : (
                              <div></div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default SpecificTaskUser;

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './views/private/home';
import References from './views/general/references';
import NewBusiness from './views/business/createNew';
import Redirect404 from './views/general/404';
import NewUser from './views/private/createNew';
import TermsAndConditions from './views/general/terms';
import About from './views/general/about';
import HomeBusiness from './views/business/home';
import RecruitmentBusiness from './views/business/recruitment';
import Faq from './views/general/faq';
import ContactUs from './views/general/contact';
import UserTaskNew from './views/private/taskNew';
import UserTaskHistory from './views/private/taskHistory';
import Profile from './views/private/profile';
import Prices from './views/business/prices';
import Tasks from './views/business/tasks';
import SpecificTask from './views/business/specificTask';
import BusinessProfile from './views/business/profile';
import SpecificTaskUser from './views/private/specificTask';
import TasksHistory from './views/business/tasks_history';

/* Single page application routes */

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/privat/opret" element={<NewUser />} />
        <Route path="/privat/hjem" element={<Home />} />
        <Route path="/privat/mine-sager" element={<UserTaskHistory />} />
        <Route path="/privat/mine-sager/:sagsid" element={<SpecificTaskUser />} />
        <Route path="/privat/opret-sag" element={<UserTaskNew />} />
        <Route path="/privat/profil" element={<Profile />} />
        <Route path="/om-os" element={<About />} />
        <Route path="/kontakt" element={<ContactUs />} />
        <Route path="/referencer" element={<References />} />
        <Route path="/erhverv" element={<RecruitmentBusiness />} />
        <Route path="/erhverv/profil" element={<BusinessProfile />} />
        <Route path="/erhverv/opret" element={<NewBusiness />} />
        <Route path="/erhverv/hjem" element={<HomeBusiness />} />
        <Route path="/erhverv/priser" element={<Prices />} />
        <Route path="/erhverv/sager" element={<Tasks />} />
        <Route path="/erhverv/sager/:sagsid" element={<SpecificTask />} />
        <Route path="/erhverv/sager-historik" element={<TasksHistory />} />
        <Route path="/vilkaar" element={<TermsAndConditions />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/*" element={<Redirect404 />} />
      </Routes>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();

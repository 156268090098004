import React from "react";
import { Helmet } from "react-helmet";
import NavigationBar from "../../../components/navigation";
import Footer from "../../../components/footer";
import LoginModal from "../../../components/loginModal";
import { useEffect, useState } from "react";
import { ITasks } from "../../../util/interfaces";
import axios from "axios";
import {
  convertDateToDkFormat,
  getSavedUserPropertyFromLocalStorage,
  useNavigatePath,
} from "../../../util/utils";
import moment from "moment";

/**
 * Inspiration: Bootstrap classes buildups inspired from documentation:
 * - https://getbootstrap.com/docs/5.3/components/card/, visited 20-10-23.
 * - https://getbootstrap.com/docs/5.3/components/, visited 20-10-23.
 *
 *
 * @returns The page where the business user sees all their old tasks so the current tasks page does not get cluttered over time
 */

function TasksHistory() {
  const toCurrentTasks = useNavigatePath("/erhverv/sager");

  const [myTasks, setMyTasks] = useState<ITasks[]>([]); //source on how to use state types: https://stackoverflow.com/questions/53650468/set-types-on-usestate-react-hook-with-typescript, visited 30-10-23.

  const getTasks_all = () => {
    //gets all tasks

    const url = "https://localhost:7083/api/Tasks/";
    axios
      .get(url)
      .then((response) => {
        console.log(response.data);
        setMyTasks(response.data);
      })
      .catch(function (error) {
        if (error.response) {
          // Error occured
          console.log(error.response.status);
          console.log(error.response.data.title);
          console.log(error.response.data);
          alert(
            "Der er sket en fejl. Tjek alle felter er udfyldt korrekt og prøv igen"
          );
        }
      });
  };

  useEffect(() => {
    //make sure getTasks is updated when page is loaded
    getTasks_all();
  }, []);

  return (
    <div>
      <div className="container-fluid">
        <Helmet>
          <title>FindDyrlæge | Sager</title>
        </Helmet>
        <div className="row">
          <div className="col-md-2">
            <NavigationBar />
          </div>
          <div className="col-md-10">
            <div className="d-flex justify-content-between mb-4">
              <div />
              <div>
                <LoginModal />
              </div>
            </div>
            <div className="col-md-10">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">
                    Historiske sager - Afsluttet for over 4 uger siden
                  </h5>
                </div>
                <div className="card-body">
                  {/* Mapping of objects below inspiration from: https://github.com/Hildan94/GRP1ProjectFinal/blob/main/web/src/pages/quizzes.js, visited 20-10-23. */}
                  <div>
                    <div>
                      <h3 className="card">Tilbud accepteret</h3>
                      <div>
                        {/* Inspiration on how to use MomentJS library for calculating number of days since endDate: https://medium.com/calculatte-age-from-date-of-birth-in-typescript/calculatte-age-from-date-of-birth-43357a74e503, visited 20-10.23. */}
                        {/* eslint-disable-next-line array-callback-return*/}
                        {myTasks.map(function (d, idx) {
                          if (
                            d.businessUserId ===
                              getSavedUserPropertyFromLocalStorage(
                                "id"
                              ).toString() &&
                            d.userAnswer === "accepteret" &&
                            moment().diff(d.endDate, "days") > 28
                          ) {
                            return (
                              <ul key={idx}>
                                <h3
                                  onClick={() => {
                                    window.location.href =
                                      "/erhverv/sager/" +
                                      d.id; /* navigate to task id */
                                  }}
                                  className="card-left"
                                >
                                  Afsluttet: {convertDateToDkFormat(d.endDate)}{" "}
                                  - {d.animalRace},{" "}
                                  {/* Inspiration for how to use MomentJS library to calculate age from date: https://medium.com/calculatte-age-from-date-of-birth-in-typescript/calculatte-age-from-date-of-birth-43357a74e503, visited 20-10-23. */}{" "}
                                  {moment().diff(d.animalBirthdate, "years")} år
                                  - {d.wantedService} - Id: {d.id}
                                </h3>
                                <br />
                              </ul>
                            );
                          }
                        })}
                      </div>
                      <br />
                    </div>

                    <div>
                      <h3 className="card">Tilbud afvist</h3>
                      <div>
                        {/* Inspiration on how to use MomentJS library for calculating number of days since endDate: https://medium.com/calculatte-age-from-date-of-birth-in-typescript/calculatte-age-from-date-of-birth-43357a74e503, visited 20-10-23. */}
                        {/* eslint-disable-next-line array-callback-return*/}
                        {myTasks.map(function (d, idx) {
                          if (
                            d.businessUserId ===
                              getSavedUserPropertyFromLocalStorage(
                                "id"
                              ).toString() &&
                            d.userAnswer === "afvist" &&
                            moment().diff(d.endDate, "days") > 28
                          ) {
                            return (
                              <ul key={idx}>
                                <h3
                                  onClick={() => {
                                    window.location.href =
                                      "/erhverv/sager/" +
                                      d.id; /* navigate to task id */
                                  }}
                                  className="card-left"
                                >
                                  Afsluttet: {convertDateToDkFormat(d.endDate)}{" "}
                                  - {d.animalRace},{" "}
                                  {/* Inspiration for how to use MomentJS library to calculate age from date: https://medium.com/calculatte-age-from-date-of-birth-in-typescript/calculatte-age-from-date-of-birth-43357a74e503, visited 20-10-23. */}{" "}
                                  {moment().diff(d.animalBirthdate, "years")} år
                                  - {d.wantedService} - Id: {d.id}
                                </h3>
                                <br />
                              </ul>
                            );
                          }
                        })}
                      </div>
                      <br />
                      <div>
                        <div>
                          <div className="text-center">
                            <button
                              onClick={toCurrentTasks}
                              type="button"
                              className="btn btn-primary mt-2"
                            >
                              Se aktuelle sager
                            </button>
                          </div>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default TasksHistory;

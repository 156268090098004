import React from "react";
import { useNavigatePath } from "../../../../util/utils";

const ClickableCards = () => {
  const toNewTask = useNavigatePath("/privat/opret-sag");
  const toMyTasks = useNavigatePath("/privat/mine-sager");
  const toProfile = useNavigatePath("/privat/profil");

  /*
  Component to be used to show clickable cards on the user home page
  */
  return (
    <div className="container">
      <div className="row">
        <div onClick={toNewTask} className="col-4">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Opret ny sag</h5>
              <p className="card-text">
                Klik her for at oprette en forespørgsel på tilbud
              </p>
            </div>
          </div>
        </div>
        <div onClick={toMyTasks} className="col-4">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Se oprettede sager</h5>
              <p className="card-text">
                Klik her for at se dine allerede oprettede sager
              </p>
            </div>
          </div>
        </div>
        <div onClick={toProfile} className="col-4">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Ret oplysninger</h5>
              <p className="card-text">
                Klik her for at se og rette dine oplysninger
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClickableCards;

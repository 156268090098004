import React from "react";
import Footer from "../../../components/footer";
import NavigationBar from "../../../components/navigation";
import { Helmet } from "react-helmet";
import axios from "axios";
import { useState } from "react";
import {
  capitalizeFirstLetter,
  doesUserEmailExistInDatabase,
  saveUserToLocalStorage,
  useNavigatePath,
} from "../../../util/utils";

/**
 * Inspirations: Bootstrap classes buildups inspired from documentation:
 * - https://getbootstrap.com/docs/5.3/components/card/, visited 20-10-23.
 * - https://getbootstrap.com/docs/5.3/components/, visited 20-10-23.
 *
 * @returns The page where a user can create a new user profile
 */

function NewUser() {
  const toHome = useNavigatePath("/privat/hjem");

  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [roadNameAndNumber, setRoadNameAndNumber] = useState("");
  const [cityName, setCityName] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [birthdate, setBirthdate] = useState("");

  const handlerChangeEvent_name = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
    //console.log("Name value is: " + name)
  };

  const handlerChangeEvent_phoneNumber = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPhoneNumber(e.target.value);
    //console.log("PhoneNumber value is: " + phoneNumber)
  };

  const handlerChangeEvent_roadNameAndNumber = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRoadNameAndNumber(e.target.value);
    //console.log("roadNameAndNumber value is: " + roadNameAndNumber)
  };

  const handlerChangeEvent_cityName = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCityName(e.target.value);
    //console.log("cityName value is: " + cityName)
  };

  const handlerChangeEvent_zipCode = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setZipCode(e.target.value);
    //console.log("zipCode value is: " + zipCode)
  };

  const handlerChangeEvent_email = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    //console.log("email value is: " + email)
  };

  const handlerChangeEvent_password = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPassword(e.target.value);
    //console.log("password value is: " + password)
  };

  const handlerChangeEvent_passwordConfirm = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPasswordConfirm(e.target.value);
    //console.log("passwordConfirm value is: " + password)
  };

  const handlerChangeEvent_birthdate = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setBirthdate(e.target.value);
    //console.log("birthdate value is: " + birthdate)
  };

  /* Inspiration on how to make the function async: https://www.codecademy.com/learn/asynchronous-javascript/modules/asynch-js/cheatsheet, visited 20-10-23. */
  const submitForm = async () => {
    //async because we need to check first if user exists, and depending on that answer create the new user or not

    if (
      //checks (are all fields filled out? and are passwords the same)
      name === "" ||
      phoneNumber === "" ||
      roadNameAndNumber === "" ||
      cityName === "" ||
      zipCode === "" ||
      email === "" ||
      password === "" ||
      birthdate === ""
    ) {
      alert("Venligst udfyld alle felter");
    } else if (password !== passwordConfirm) {
      alert("De to kodeord er ikke ens");
    } else {
      const attemptedUserAlreadyExists = await doesUserEmailExistInDatabase(
        email
      );
      if (!attemptedUserAlreadyExists) {
        //user does not already exist
        postUser();
      } else {
        //user already exists
        alert("Der findes allerede en bruger med denne email");
      }
    }
  };

  const postUser = () => {
    var object = {
      isBusinessUser: false, //always false when regular user
      name: capitalizeFirstLetter(name),
      phoneNumber: phoneNumber,
      roadNameAndNumber: capitalizeFirstLetter(roadNameAndNumber),
      cityName: capitalizeFirstLetter(cityName),
      zipCode: zipCode,
      email: email,
      password: password,
      birthdate: birthdate + "T00:00:00.000Z",
    };

    axios
      .post("https://localhost:7083/api/Users", object)
      .then((response) => {
        console.log(response.data);
        saveUserToLocalStorage(response.data); //save user
        toHome(); //redirect to home page
      })
      .catch(function (error) {
        if (error.response) {
          // Error occured
          console.log(error.response.status);
          console.log(error.response.data.title);
          console.log(error.response.data);
          alert(
            "Der er sket en fejl. Tjek alle felter er udfyldt korrekt og prøv igen"
          );
        }
      });
  };

  return (
    <div>
      <div className="container-fluid">
        <Helmet>
          <title>FindDyrlæge | Opret</title>
        </Helmet>
        <div className="row">
          <div className="col-md-2">
            <NavigationBar />
          </div>
          <div className="col-md-10">
            <div className="card">
              <div className="card-header">
                <h5 className="card-title">Indtast dine oplysninger:</h5>
              </div>
              <div className="card-body">
                {/* Class styles (classNames) inspiration from LoginModal component*/}
                <form>
                  <div className="form-containerspacing">
                    <label>Fulde navn</label>
                    <input
                      type="text"
                      className="form-control"
                      id="inputName"
                      placeholder="Indtast"
                      onChange={handlerChangeEvent_name}
                    />
                  </div>
                  <div className="form-containerspacing">
                    <label>Telefonnummer</label>
                    <input
                      type="number"
                      className="form-control"
                      id="inputPhoneNo"
                      placeholder="Indtast"
                      onChange={handlerChangeEvent_phoneNumber}
                    />
                  </div>
                  <div className="form-containerspacing">
                    <label>Vejnavn og nummer</label>
                    <input
                      type="text"
                      className="form-control"
                      id="inputRoadAndNo"
                      placeholder="Indtast"
                      onChange={handlerChangeEvent_roadNameAndNumber}
                    />
                  </div>
                  <div className="form-containerspacing">
                    <label>Bynavn</label>
                    <input
                      type="text"
                      className="form-control"
                      id="inputCityName"
                      placeholder="Indtast"
                      onChange={handlerChangeEvent_cityName}
                    />
                  </div>
                  <div className="form-containerspacing">
                    <label>Postnummer</label>
                    <input
                      type="number"
                      className="form-control"
                      id="inputZipCode"
                      placeholder="Indtast"
                      onChange={handlerChangeEvent_zipCode}
                    />
                  </div>
                  <div className="form-containerspacing">
                    <label>E-mail</label>
                    <input
                      placeholder="Indtast e-mail"
                      id="loginEmail"
                      className="form-control"
                      type="email"
                      onChange={handlerChangeEvent_email}
                    />
                  </div>
                  <div className="form-containerspacing">
                    <label>Fødselsdato</label>
                    <input
                      type="date"
                      className="form-control"
                      id="birthdate"
                      onChange={handlerChangeEvent_birthdate}
                    />
                  </div>
                  <div className="form-containerspacing">
                    <label>Kodeord</label>
                    <input
                      placeholder="Indtast kodeord"
                      id="loginPassword"
                      className="form-control"
                      type="password"
                      onChange={handlerChangeEvent_password}
                    />
                  </div>
                  <div className="form-containerspacing">
                    <label>Bekræft kodeord</label>
                    <input
                      placeholder="Indtast kodeord"
                      id="loginPasswordConfirm"
                      className="form-control"
                      type="password"
                      onChange={handlerChangeEvent_passwordConfirm}
                    />
                  </div>
                  <div onClick={submitForm} className="loginBtn">
                    <button type="button" className="btn btn-primary mt-2">
                      Gem
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default NewUser;

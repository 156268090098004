import React from 'react';
import { useModal } from "react-hooks-use-modal";
import "./style.scss";
import { useEffect, useState } from "react";
import axios from "axios";
import { saveUserToLocalStorage, useNavigatePath } from "../../util/utils";

/*
Component that includes login button and the popup menu (modal) to login 

Inspiration: 
- https://github.com/madsstorgaardnielsen/group5-proj/blob/master/fbcmanager-frontend/src/components/login_component/LoginPopup.jsx, visited 20-10-23.
- https://getbootstrap.com/docs/5.3/components/dropdowns/, visited 20-10-23.
*/

const LoginModal = () => {
  const toFrontpage = useNavigatePath("/");
  const toHome = useNavigatePath("/privat/hjem");
  const toBusinessHome = useNavigatePath("/erhverv/hjem");
  const toSignup = useNavigatePath("/privat/opret");
  const toBusinessSignup = useNavigatePath("/erhverv/opret");

  const [isLoggedIn, setIsLoggedIn] = useState(false); //used to conditonally render login/logout button
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [Modal, openModal] = useModal("root", {
    preventScroll: true,
  });

  const handlerChangeEvent_email = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    //console.log("email value is: " + email);
  };

  const handlerChangeEvent_password = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPassword(e.target.value);
    //console.log("password value is: " + password);
  };

  useEffect(() => {
    const getUserFromLocalStorage = () => {
      //get the user from local storage to determine conditional rendering

      var getUser = localStorage.getItem("user");
      if (getUser != null) {
        //console.log(JSON.parse(getUser));
        setIsLoggedIn(true); // if user != null then user is logged in
      }
    };

    getUserFromLocalStorage();
  }, []);

  const logout = () => {
    localStorage.removeItem("user"); //remover user from local storage if it exists, else do nothing
    setIsLoggedIn(false);

    if (window.location.pathname === "/") {
      //if the user already is at frontpage, reload page to update navbar options
      window.location.reload();
    } else {
      toFrontpage();
    }
  };

  const getUserFromEmail = () => {
    axios
      .get("https://localhost:7083/api/Users/email/" + email)
      .then((response) => {
        console.log(response.data);
        if (response.data.password === password) {
          console.log("Credentials OK");

          saveUserToLocalStorage(response.data); //keep current user in local storage

          if (response.data.isBusinessUser === true) {
            //business user
            toBusinessHome();
          } else {
            //private user
            toHome();
          }
        } else {
          alert("Brugeroplysninger kunne ikke valideres. Prøv igen.");
          console.log("User info incorrect");
        }
      })
      .catch(function (error) {
        if (error.response) {
          // Error occured
          console.log(error.response.status);
          console.log(error.response.data.title);
          console.log(error.response.data);
          alert("Brugeroplysninger kunne ikke valideres. Prøv igen.");
        }
      });
  };

  //check url path for "erhverv" and if it is present, then navigate to business signup. Else navigate to private signup
  const navToCorrectSignupPage = () => {
    const path = window.location.pathname;
    const stringToCheck = "erhverv";

    const substringCheck = path.includes(stringToCheck);
    console.log(substringCheck);

    if (substringCheck) {
      toBusinessSignup();
    } else {
      toSignup();
    }
  };

  return (
    <div>
      {isLoggedIn ? (
        <div>
          <button onClick={logout} className="btn btn-primary">
            Log ud
          </button>
        </div>
      ) : (
        <div>
          <button
            onClick={navToCorrectSignupPage}
            className="btn btn-primary me-2"
          >
            Tilmeld
          </button>
          <button onClick={openModal} className="btn btn-primary">
            Log ind
          </button>
        </div>
      )}

      <Modal>
        <div className="modalBox">
          <form>
            <div className="form-containerspacing">
              <label>E-mail</label>
              <input
                placeholder="Indtast e-mail"
                id="loginEmail"
                className="form-control"
                type="email"
                onChange={handlerChangeEvent_email}
              />
            </div>
            <div className="form-containerspacing">
              <label>Kodeord</label>
              <input
                placeholder="Indtast kodeord"
                id="loginPassword"
                className="form-control"
                type="password"
                onChange={handlerChangeEvent_password}
              />
            </div>
            <div className="loginButton">
              <button
                onClick={getUserFromEmail}
                type="button"
                className="btn btn-primary"
              >
                Log ind
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default LoginModal;

import React from "react";
import { Helmet } from "react-helmet";
import NavigationBar from "../../../components/navigation";
import Footer from "../../../components/footer";
import LoginModal from "../../../components/loginModal";
import axios from "axios";
import {
  convertDateToDkFormat,
  getCorrectDateAndTime,
  getSavedUserPropertyFromLocalStorage,
  useNavigatePath,
} from "../../../util/utils";
import { useEffect, useState } from "react";
import { IMedicinePrices, ITasks, IUser } from "../../../util/interfaces";
import moment from "moment";

/**
 * Inspiration: Bootstrap classes buildups inspired from documentation:
 * - https://getbootstrap.com/docs/5.3/components/card/, visited 20-10-23.
 * - https://getbootstrap.com/docs/5.3/components/, visited 20-10-23.
 *
 *
 * @returns The page where the business user sees a specific task from id
 */

function SpecificTask() {
  const toTasks = useNavigatePath("/erhverv/sager");

  const getTaskIdNumber = window.location.pathname.replace(
    "/erhverv/sager/",
    ""
  );
  const [myTask, setMyTask] = useState<ITasks>();
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [medicine, setMedicine] = useState<IMedicinePrices[]>([]); //source on how to use state type: https://stackoverflow.com/questions/53650468/set-types-on-usestate-react-hook-with-typescript, visited 20-10-23.
  const [selectedMedicinePrice, setSelectedMedicinePrice] = useState("");
  const [selectedMedicineName, setSelectedMedicineName] = useState("");
  const [userIdOfPrivateUser, setUserIdOfPrivateUser] = useState<IUser>();

  const [dogConsultation, setDogConsultation] = useState("");
  const [dogVaccination, setDogVaccination] = useState("");
  const [dogCastration, setDogCastration] = useState("");
  const [catConsultation, setCatConsultation] = useState("");
  const [catVaccination, setCatVaccination] = useState("");
  const [catCastration, setCatCastration] = useState("");

  const submitOffer = () => {
    //check if all fields are filled out
    if (
      description === "" ||
      description === null ||
      price === "" ||
      price === null
    ) {
      alert("Venligst udfyld alle felter");
      return;
    }

    if (
      //check if medicine is selected
      selectedMedicineName !== "Vælg medicin" &&
      selectedMedicineName !== ""
    ) {
      //medicine selected
      updateTask(description, Number(price) + Number(selectedMedicinePrice));
    } else {
      //no medicine selected
      updateTask(description, parseInt(price));
    }
  };

  /* Updates the task when business user sends an offer */
  const updateTask = (vetDescription: string, priceoffer: number) => {
    var object = {
      id: getTaskIdNumber,
      userId: myTask?.userId,
      businessUserId: getSavedUserPropertyFromLocalStorage("id").toString(),
      wantedService: myTask?.wantedService,
      animalRace: myTask?.animalRace,
      animalName: myTask?.animalName,
      userAnswer: myTask?.userAnswer,
      requiredMedicineName: selectedMedicineName,
      requiredMedicinePrice: Number(selectedMedicinePrice),
      areaOfInterest: myTask?.areaOfInterest,
      status: "Tilbud sendt",
      animalBirthdate: myTask?.animalBirthdate,
      creationDate: myTask?.creationDate,
      endDate: myTask?.endDate,
      offerDate: getCorrectDateAndTime(),
      descriptionFromVet: vetDescription,
      price: priceoffer,
    };

    const url = "https://localhost:7083/api/Tasks/" + getTaskIdNumber;
    axios
      .put(url, object)
      .then((response) => {
        console.log(response.data);
        window.location.reload();
      })
      .catch(function (error) {
        if (error.response) {
          // Error occured
          console.log(error.response.status);
          console.log(error.response.data.title);
          console.log(error.response.data);
          alert("Der er sket en fejl. Prøv igen");
        }
      });
  };

  /* Gets the task for the business user */
  const getTask = () => {
    const url = "https://localhost:7083/api/Tasks/" + getTaskIdNumber;
    axios
      .get(url)
      .then((response) => {
        console.log(response.data);
        setMyTask(response.data);
        setDescription(response.data.descriptionFromVet);
        setPrice(response.data.price.toString());
      })
      .catch(function (error) {
        if (error.response) {
          // Error occured
          console.log(error.response.status);
          console.log(error.response.data.title);
          console.log(error.response.data);
          alert("Der er sket en fejl. Prøv igen");
        }
      });
  };

  /* Gets medicine information */
  const getMedicine = () => {
    const url = "https://localhost:7083/api/MedicinePrices";
    axios
      .get(url)
      .then((response) => {
        console.log(response.data);
        setMedicine(response.data);
      })
      .catch(function (error) {
        if (error.response) {
          // Error occured
          console.log(error.response.status);
          console.log(error.response.data.title);
          console.log(error.response.data);
          alert("Der er sket en fejl. Prøv igen");
        }
      });
  };

  /* Gets info about user from their id */
  const getUserFromId = () => {
    if (
      //do nothing if the user id is not gotten yet
      userIdOfPrivateUser?.name === undefined ||
      userIdOfPrivateUser?.name === null ||
      userIdOfPrivateUser?.name === ""
    ) {
      axios
        .get("https://localhost:7083/api/Users/" + myTask?.userId)
        .then((response) => {
          console.log(response.data);
          console.log(response.data.id);
          console.log(response.data.name);
          setUserIdOfPrivateUser(response.data);
        })
        .catch(function (error) {
          if (error.response) {
          }
        });
    }
  };

  // Update the recommended prices to the view depending on what service the customer wants
  const getRecommendedPrices = () => {
    switch (myTask?.animalRace + " " + myTask?.wantedService) {
      case "Hund Konsultation":
        return dogConsultation || 0;
      case "Hund Vaccination":
        return dogVaccination || 0;
      case "Hund Sterilisation":
        return dogCastration || 0;
      case "Kat Konsultation":
        return catConsultation || 0;
      case "Kat Vaccination":
        return catVaccination || 0;
      case "Kat Sterilisation":
        return catCastration || 0;
      default:
        return 0; //null
    }
  };

  /* Get the prices of the business user */
  const getPrices = () => {
    axios
      .get(
        "https://localhost:7083/api/Prices/userid/" +
          getSavedUserPropertyFromLocalStorage("id")
      )
      .then((response) => {
        console.log(response.data);
        setDogConsultation(response.data.dogConsultation);
        setDogVaccination(response.data.dogVaccination);
        setDogCastration(response.data.dogNeutralisation);
        setCatConsultation(response.data.catConsultation);
        setCatVaccination(response.data.catVaccination);
        setCatCastration(response.data.catNeutralisation);
      })
      .catch(function (error) {
        if (error.response) {
          // Error occured
          if (error.response.status === 404) {
            //user has not created prices yet
            return;
          }
          console.log(error.response.status);
          console.log(error.response.data.title);
          console.log(error.response.data);
          alert(
            "Der er sket en fejl. Tjek alle felter er udfyldt korrekt og prøv igen"
          );
        }
      });
  };

  const handlerChangeEvent_price = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPrice(e.target.value);
    //console.log("setPrice value is: " + price);
  };

  const handlerChangeEvent_description = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setDescription(e.target.value);
    //console.log("description value is: " + description);
  };

  const handlerChangeEvent_selectedMedicine = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedMedicineName(e.target.options[e.target.selectedIndex].text);
    setSelectedMedicinePrice(e.target.value);

    /*console.log(
      "SelectedMedicineName" + e.target.options[e.target.selectedIndex].text
    );
    console.log("SelectedMedicinePrice is: " + e.target.value); */
  };

  useEffect(() => {
    getTask(); //get the task info
    getMedicine(); //get medicine prices
    getPrices(); //get the prices of the business user

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUserFromId()]); //gets the private user info once user id is gotten

  return (
    <div>
      <div className="container-fluid">
        <Helmet>
          <title>FindDyrlæge | Sag</title>
        </Helmet>
        <div className="row">
          <div className="col-md-2">
            <NavigationBar />
          </div>
          <div className="col-md-10">
            <div className="d-flex justify-content-between mb-4">
              <div />
              <div>
                <LoginModal />
              </div>
            </div>
            <div className="col-md-10">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">Sag id {getTaskIdNumber}</h5>
                </div>
                <div className="card-body">
                  <div>
                    <h3 className="card-left text-center">Om sagen</h3>
                    <p className="card-text text-center ">
                      Startdato:{" "}
                      {convertDateToDkFormat(myTask?.creationDate || "")}.{" "}
                      <br />
                      Status: {myTask?.status}. <br />
                      Ydelse: {myTask?.wantedService}, {myTask?.areaOfInterest}.
                      <br />
                      <br />
                      <h5>Om dyret:</h5>
                      Navn: {myTask?.animalName}. <br />
                      Race: {myTask?.animalRace}. <br />
                      Fødselsdato:{" "}
                      {convertDateToDkFormat(
                        myTask?.animalBirthdate || ""
                      )}. <br />
                      Alder:{" "}
                      {/* Inspiration for how to use MomentJS library to calculate age from date: https://medium.com/calculatte-age-from-date-of-birth-in-typescript/calculatte-age-from-date-of-birth-43357a74e503, visited 20-10-23. */}
                      {moment().diff(myTask?.animalBirthdate, "years")} år.
                    </p>
                    <br />
                  </div>

                  {myTask?.status !== "Oprettet" ? ( //Conditional render, so the business user gets different views depending on if they have sent an offer to the customer
                    <div>
                      <h3 className="card-left text-center">Afgivet tilbud</h3>
                      <p className="card-text text-center">
                        Sendt: {convertDateToDkFormat(myTask?.offerDate || "")}.{" "}
                        <br />
                        Beskrivelse: {myTask?.descriptionFromVet}. <br />
                        <br />
                        <h5>Pris:</h5>
                        {myTask?.requiredMedicinePrice !== 0 &&
                        myTask?.requiredMedicinePrice !== null ? ( //only show info about medicine, if any has been selected
                          <div>
                            <br />
                            Pris inkl. medicin: {myTask?.price} kr. <br />
                            Pris ekskl. medicin:{" "}
                            {Number(myTask?.price) -
                              Number(myTask?.requiredMedicinePrice)}{" "}
                            kr. <br />
                            <br />
                            <h5>Medicin specifikation:</h5>
                            Navn: {myTask?.requiredMedicineName}
                            <br />
                            Dagspris d.{" "}
                            {convertDateToDkFormat(
                              myTask?.offerDate || ""
                            )}: {myTask?.requiredMedicinePrice},00 kr.
                          </div>
                        ) : (
                          <div>
                            Pris: {myTask?.price} kr. <br />
                          </div>
                        )}
                      </p>

                      {myTask?.endDate !== "" && myTask?.endDate !== null ? ( //only show info about answer, if an answer has been given (if an answer has been given, enddate is filled out)
                        <div>
                          <br />
                          <h3 className="card-left text-center">
                            Svar fra kunde
                          </h3>
                          <p className="card-text text-center">
                            Svar: Tilbud {myTask?.userAnswer}. <br />
                            Svar dato:{" "}
                            {convertDateToDkFormat(myTask?.endDate || "")}.
                          </p>
                        </div>
                      ) : (
                        <div></div>
                      )}

                      {myTask?.userAnswer === "accepteret" ? ( //only show user info, if offer is accepted
                        <div>
                          <br />
                          <br />
                          <h3 className="card-left text-center">
                            Kundeoplysninger
                          </h3>
                          <p className="card-text text-center">
                            Navn: {userIdOfPrivateUser?.name}. <br />
                            Tlf. nr.: +45 {
                              userIdOfPrivateUser?.phoneNumber
                            }. <br />
                            E-mail: {userIdOfPrivateUser?.email}. <br />
                          </p>
                          <p className="card-text text-center">
                            Kunde er bedt kontakte klinikken senest 14 dage fra
                            svardatoen,{" "}
                            {convertDateToDkFormat(myTask?.endDate || "")}.
                          </p>
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </div>
                  ) : (
                    <div>
                      <div>
                        <h3 className="card-left text-center">Medicin</h3>
                        <p className="card-text ">
                          Hvis ydelsen kræver medicin, kan I vælge medicin
                          herunder. <br /> Prisen er hentet fra dagsprisen dags
                          dato, d.{" "}
                          {convertDateToDkFormat(getCorrectDateAndTime() || "")}
                          .
                        </p>
                        <div className="form-containerspacing">
                          <label>Vælg nødvendig medicin</label>
                          <select
                            onChange={handlerChangeEvent_selectedMedicine}
                            className="form-select"
                            id="selectArea"
                          >
                            <option selected>Vælg medicin</option>
                            {/* Mapping of object below inspiration from: https://github.com/Hildan94/GRP1ProjectFinal/blob/main/web/src/pages/quizzes.js, visited 20-10-23. */}
                            {medicine?.map(function (d, idx) {
                              return (
                                <option key={idx} value={d.priceInclVat}>
                                  {d.name}
                                </option>
                              );
                            })}
                          </select>

                          {selectedMedicineName !== "Vælg medicin" &&
                          selectedMedicineName !== "" ? (
                            <p className="mt-2">
                              Dagspris inkl. moms: {selectedMedicinePrice},00
                              kr.
                            </p>
                          ) : (
                            <div />
                          )}
                        </div>

                        <br />
                      </div>
                      {dogConsultation !== "" &&
                      dogConsultation !== null &&
                      Number(dogConsultation) !== 0 ? ( //only show info about prices, if the business user has created prices - (if info for dogConsultation is filled out, assume other prices are filled out as well)
                        <div>
                          <h3 className="card-left text-center">
                            Anbefalet pris jf. prisliste
                          </h3>
                          <p className="card-text ">
                            På jeres prisliste har I angivet følgende pris for:{" "}
                            {myTask?.wantedService +
                              ". " +
                              myTask?.animalRace +
                              "."}
                          </p>
                          <div className="form-containerspacing">
                            <div className="form-containerspacing">
                              <label>Beløb i DKK, ekskl. medicin</label>
                              <input
                                type="number"
                                className="form-control"
                                id="inputRecommendedPrice"
                                placeholder=""
                                disabled
                                value={getRecommendedPrices()}
                              />
                            </div>
                          </div>

                          <br />
                        </div>
                      ) : (
                        <div>
                          <h3 className="card-left text-center">
                            Anbefalet pris jf. prisliste
                          </h3>
                          <p className="card-text ">
                            Når I har indtastet jeres priser, vil de relevante
                            blive vist her.
                          </p>
                          <br />
                        </div>
                      )}
                      <div>
                        <h3 className="card-left text-center">Afgiv tilbud</h3>
                        <p className="card-text ">
                          Nedenfor kan I angive tilbuddets pris samt tilknytte
                          en beskrivelse til kunden. Klinikkens
                          kontaktoplysninger videregives automatisk til kunden.
                        </p>
                        {/* Class styles (classNames) inspiration from LoginModal component*/}
                        <div className="form-containerspacing">
                          <label>Beløb i DKK, ekskl. medicin</label>
                          <input
                            type="number"
                            className="form-control"
                            id="inputPriceOffer"
                            placeholder="Indtast beløb"
                            value={price}
                            onChange={handlerChangeEvent_price}
                          />
                        </div>
                        {selectedMedicineName !== "Vælg medicin" &&
                        selectedMedicineName !== "" ? (
                          <div className="form-containerspacing">
                            <label>Beløb i DKK, inkl. medicin</label>
                            <input
                              type="number"
                              className="form-control"
                              id="inputPriceOffer"
                              placeholder=""
                              disabled
                              value={
                                Number(price) + Number(selectedMedicinePrice)
                              }
                              onChange={handlerChangeEvent_price}
                            />
                          </div>
                        ) : (
                          <div />
                        )}

                        <div className="form-containerspacing">
                          <label className="form-label">Beskrivelse</label>
                          <textarea
                            className="form-control"
                            id="content"
                            placeholder="Indtast beskrivelse"
                            rows={4}
                            required
                            value={description}
                            onChange={handlerChangeEvent_description}
                          ></textarea>
                        </div>
                        <div>
                          <button
                            onClick={submitOffer}
                            type="button"
                            className="btn btn-primary mt-2"
                          >
                            Send
                          </button>
                          <button
                            onClick={toTasks}
                            type="button"
                            className="btn btn-secondary mt-2"
                          >
                            Tilbage
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default SpecificTask;

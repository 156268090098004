import React from "react";
import { Helmet } from "react-helmet";
import NavigationBar from "../../../components/navigation";
import Footer from "../../../components/footer";
import LoginModal from "../../../components/loginModal";

/**
 *
 * Inspiration: Bootstrap classes buildups inspired from documentation:
 * - https://getbootstrap.com/docs/5.3/components/card/, visited 20-10-23.
 * - https://getbootstrap.com/docs/5.3/components/, visited 20-10-23.
 *
 * @returns The page where users can read more about the company
 */

function About() {
  return (
    <div>
      <div className="container-fluid">
        <Helmet>
          <title>FindDyrlæge | Om os</title>
        </Helmet>
        <div className="row">
          <div className="col-md-2">
            <NavigationBar />
          </div>
          <div className="col-md-10">
            <div className="d-flex justify-content-between mb-4">
              <div />
              <div>
                <LoginModal />
              </div>
            </div>
            <div className="col-md-10">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">Hvem er vi?</h5>
                </div>
                <div className="card-body">
                  <p className="card-text">
                    <strong>Velkommen til FindDyrlæge!</strong>
                    <br />
                    <br />
                    Hej og velkommen. Mit navn er Frederik og jeg er studerende
                    ved Danmarks Tekniske Universitet, DTU. Denne webplatform er
                    lavet som en løsning til at assistere dyreejere i hele
                    landet med at finde gode dyrlæger i nærområdet.
                    <br />
                    <br />
                    Projektet er lavet som afgangsprojekt på Diplomingeniør
                    Softwareteknologi i efteråret 2023.
                    <br />
                    <br />
                    Frederik
                    <br />
                    Stifter, FindDyrlæge
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default About;

import React from "react";
import { Helmet } from "react-helmet";
import NavigationBar from "../../../components/navigation";
import { useNavigatePath } from "../../../util/utils";
import Footer from "../../../components/footer";
import LoginModal from "../../../components/loginModal";

/**
 *
 * Inspiration: Bootstrap classes buildups inspired from documentation:
 * - https://getbootstrap.com/docs/5.3/components/card/, visited 20-10-23.
 * - https://getbootstrap.com/docs/5.3/components/, visited 20-10-23.
 *
 * @returns The page with information about why a business user should sign up with the service
 */

function RecruitmentBusiness() {
  const toBusinessSignup = useNavigatePath("/erhverv/opret");

  return (
    <div>
      <div className="container-fluid">
        <Helmet>
          <title>FindDyrlæge | Klinik</title>
        </Helmet>
        <div className="row">
          <div className="col-md-2">
            <NavigationBar />
          </div>
          <div className="col-md-10">
            <div className="d-flex justify-content-between mb-4">
              <div />
              <div>
                <LoginModal />
              </div>
            </div>
            <div className="col-md-10">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">
                    Hvad kan vi tilbyde jeres klinik?
                  </h5>
                </div>
                <div className="card-body">
                  <h3 className="card-left">Efterspørgsel</h3>
                  <p className="card-text">
                    Der kommer hver dag flere og flere kæledyr til verden, og de
                    har brug for dyrlægeydelser. Siden oprettelsen af platformen
                    har vi haft en stødt stigning i antal kunder.
                  </p>

                  <h3 className="card-left">Bliv set</h3>
                  <p className="card-text">
                    Det kan være svært at få nye kunder. Det kan vi hjælpe med,
                    da I vil kunne række ud til endnu flere kunder end uden
                    vores platform.
                  </p>

                  <h3 className="card-left">Styr selv vilkårene</h3>
                  <p className="card-text">
                    I kan næsten helt selv få lov at bestemme hvordan I vil
                    benytte platformen og i hvilket omfang. Man er ikke bundet
                    af nogen mindste volumen eller forventet aktivitet.
                  </p>

                  <h3 className="card-left">Risikofrit</h3>
                  <p className="card-text">
                    Der er ingen omkostninger forbundet ved oprettelsen af
                    profilen på platformen, så der er intet at miste, men et
                    stort muligt potentiale i en masse nye kunder at vinde.
                  </p>

                  <h3 className="card-left">Altid klar til at hjælpe</h3>
                  <p className="card-text">
                    Vi har Danmarks bedste kundeservice - i hvert fald hvis man
                    spørger os selv. Vi står klar til at hjælpe jer med alle de
                    spørgsmål som I kunne have.
                  </p>

                  <p className="card-text">
                    Opret jeres dyrlæge klinik i dag og oplev alt hvad vi kan
                    tilbyde.
                  </p>
                </div>
                <div className="col-12 mt-2 mb-2 text-center">
                  <button
                    onClick={toBusinessSignup}
                    className="btn btn-primary me-2"
                  >
                    Klik for at oprette klinik
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default RecruitmentBusiness;

import React from "react";
import { Helmet } from "react-helmet";
import NavigationBar from "../../../components/navigation";
import { getSavedUserPropertyFromLocalStorage } from "../../../util/utils";
import Footer from "../../../components/footer";
import LoginModal from "../../../components/loginModal";
import axios from "axios";
import { useEffect, useState } from "react";

/**
 *
 * Inspiration: Bootstrap classes buildups inspired from documentation:
 * - https://getbootstrap.com/docs/5.3/components/card/, visited 20-10-23.
 * - https://getbootstrap.com/docs/5.3/components/, visited 20-10-23.
 * - https://getbootstrap.com/docs/5.3/forms/checks-radios/, visited 20-10-23.
 *
 * @returns The page where the business user sees and edits their prices
 */

function Prices() {
  const [pricesId, setPricesId] = useState(""); //used for updating prices via http put
  const [dogConsultation, setDogConsultation] = useState("");
  const [dogVaccination, setDogVaccination] = useState("");
  const [dogCastration, setDogCastration] = useState("");
  const [catConsultation, setCatConsultation] = useState("");
  const [catVaccination, setCatVaccination] = useState("");
  const [catCastration, setCatCastration] = useState("");

  const handlerChangeEvent_dogConsultation = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDogConsultation(e.target.value);
    //console.log("dogConsultation value is: " + dogConsultation);
  };

  const handlerChangeEvent_dogVaccination = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDogVaccination(e.target.value);
    //console.log("dogVaccination value is: " + dogVaccination);
  };

  const handlerChangeEvent_dogCastration = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDogCastration(e.target.value);
    //console.log("dogCastration value is: " + dogCastration);
  };

  const handlerChangeEvent_catConsultation = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCatConsultation(e.target.value);
    //console.log("catConsultation value is: " + catConsultation);
  };

  const handlerChangeEvent_catVaccination = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCatVaccination(e.target.value);
    //console.log("catVaccination value is: " + catVaccination);
  };

  const handlerChangeEvent_catCastration = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCatCastration(e.target.value);
    //console.log("catCastration value is: " + catCastration);
  };

  //try to get prices from db, if not found, then post default prices
  const getPrices = () => {
    axios
      .get(
        "https://localhost:7083/api/Prices/userid/" +
          getSavedUserPropertyFromLocalStorage("id")
      )
      .then((response) => {
        console.log(response.data);
        setPricesId(
          response.data.id
        ) /*set PricesId to have the id of the prices. Needed to update later */;
        setDogConsultation(response.data.dogConsultation);
        setDogVaccination(response.data.dogVaccination);
        setDogCastration(response.data.dogNeutralisation);
        setCatConsultation(response.data.catConsultation);
        setCatVaccination(response.data.catVaccination);
        setCatCastration(response.data.catNeutralisation);
      })
      .catch(function (error) {
        if (error.response) {
          // Error occured
          if (error.response.status === 404) {
            console.log(
              "User not created prices yet. Default prices will be posted"
            );
            postPrices();
            return;
          }
          console.log(error.response.status);
          console.log(error.response.data.title);
          console.log(error.response.data);
          alert(
            "Der er sket en fejl. Tjek alle felter er udfyldt korrekt og prøv igen"
          );
        }
      });
  };

  /* Updates the prices entry in database. Uses parameter "pricesId". To be used to save any changes to the prices. 
     Beware that pricesId will be null if updatePrices is run before either getPrices or postPrices (which are the ones that set a value for pricesId)
  */
  const updatePrices = () => {
    //console.log("pricesId is: " + pricesId);
    var object = {
      id: pricesId,
      userId: getSavedUserPropertyFromLocalStorage("id").toString(),
      dogConsultation: dogConsultation,
      dogVaccination: dogVaccination,
      dogNeutralisation: dogCastration,
      catConsultation: catConsultation,
      catVaccination: catVaccination,
      catNeutralisation: catCastration,
    };

    axios
      .put("https://localhost:7083/api/Prices/" + pricesId, object)
      .then((response) => {
        console.log(response.data);
        setPricesId(
          response.data.id
        ); /*set PricesId to have the id of the prices. Needed to update later */
        setDogConsultation(response.data.dogConsultation);
        setDogVaccination(response.data.dogVaccination);
        setDogCastration(response.data.dogNeutralisation);
        setCatConsultation(response.data.catConsultation);
        setCatVaccination(response.data.catVaccination);
        setCatCastration(response.data.catNeutralisation);
        window.location.reload();
      })
      .catch(function (error) {
        if (error.response) {
          // Error occured
          console.log(error.response.status);
          console.log(error.response.data.title);
          console.log(error.response.data);
          alert(
            "Der er sket en fejl. Tjek alle felter er udfyldt korrekt og prøv igen"
          );
        }
      });
  };

  //user has not created prices yet, so post 0-value prices
  const postPrices = () => {
    var object = {
      id: 0,
      userId: getSavedUserPropertyFromLocalStorage("id").toString(),
      dogConsultation: 0,
      dogVaccination: 0,
      dogNeutralisation: 0,
      catConsultation: 0,
      catVaccination: 0,
      catNeutralisation: 0,
    };

    axios
      .post("https://localhost:7083/api/Prices", object)
      .then((response) => {
        console.log(response.data);
        setPricesId(
          response.data.id
        ) /*set PricesId to have the id of the prices. Needed to update later */;
        setDogConsultation(response.data.dogConsultation);
        setDogVaccination(response.data.dogVaccination);
        setDogCastration(response.data.dogNeutralisation);
        setCatConsultation(response.data.catConsultation);
        setCatVaccination(response.data.catVaccination);
        setCatCastration(response.data.catNeutralisation);
      })
      .catch(function (error) {
        if (error.response) {
          // Error occured
          console.log(error.response.status);
          console.log(error.response.data.title);
          console.log(error.response.data);
          alert(
            "Der er sket en fejl. Tjek alle felter er udfyldt korrekt og prøv igen"
          );
        }
      });
  };

  useEffect(() => {
    //get and update the prices in the view
    getPrices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); //needs empty array to avoid infinite loop

  return (
    <div>
      <div className="container-fluid">
        <Helmet>
          <title>FindDyrlæge | Priser</title>
        </Helmet>
        <div className="row">
          <div className="col-md-2">
            <NavigationBar />
          </div>
          <div className="col-md-10">
            <div className="d-flex justify-content-between mb-4">
              <div />
              <div>
                <LoginModal />
              </div>
            </div>
            {/* Class styles (classNames) inspiration from LoginModal component*/}
            <div className="col-md-10">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">
                    Indtast klinikkens vejledende priser
                  </h5>
                </div>
                <div className="card-body">
                  <form>
                    <div className="form-containerspacing">
                      <label>Hund: Konsultation</label>
                      <input
                        type="number"
                        className="form-control"
                        id="inputPrice1"
                        placeholder="Indtast pris"
                        value={dogConsultation}
                        onChange={handlerChangeEvent_dogConsultation}
                      />
                    </div>
                    <div className="form-containerspacing">
                      <label>Hund: Vaccination</label>
                      <input
                        type="number"
                        className="form-control"
                        id="inputPrice2"
                        placeholder="Indtast pris"
                        value={dogVaccination}
                        onChange={handlerChangeEvent_dogVaccination}
                      />
                    </div>
                    <div className="form-containerspacing">
                      <label>Hund: Sterilisation</label>
                      <input
                        type="number"
                        className="form-control"
                        id="inputPrice3"
                        placeholder="Indtast pris"
                        value={dogCastration}
                        onChange={handlerChangeEvent_dogCastration}
                      />
                    </div>
                    <div className="form-containerspacing">
                      <label>Kat: Konsultation</label>
                      <input
                        type="number"
                        className="form-control"
                        id="inputPrice4"
                        placeholder="Indtast pris"
                        value={catConsultation}
                        onChange={handlerChangeEvent_catConsultation}
                      />
                    </div>
                    <div className="form-containerspacing">
                      <label>Kat: Vaccination</label>
                      <input
                        type="number"
                        className="form-control"
                        id="inputPrice5"
                        placeholder="Indtast pris"
                        value={catVaccination}
                        onChange={handlerChangeEvent_catVaccination}
                      />
                    </div>
                    <div className="form-containerspacing">
                      <label>Kat: Sterilisation</label>
                      <input
                        type="number"
                        className="form-control"
                        id="inputPrice6"
                        placeholder="Indtast pris"
                        value={catCastration}
                        onChange={handlerChangeEvent_catCastration}
                      />
                    </div>
                    <div className="loginBtn">
                      <button
                        onClick={updatePrices}
                        type="button"
                        className="btn btn-primary mt-2"
                      >
                        Gem
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Prices;

import React from "react";
import { Helmet } from "react-helmet";
import NavigationBar from "../../../components/navigation";
import { getCorrectDateAndTime } from "../../../util/utils";
import Footer from "../../../components/footer";
import LoginModal from "../../../components/loginModal";
import { useState } from "react";
import axios from "axios";

/**
 *
 * Inspiration: Bootstrap classes buildups inspired from documentation:
 * - https://getbootstrap.com/docs/5.3/components/card/, visited 20-10-23.
 * - https://getbootstrap.com/docs/5.3/components/, visited 20-10-23.
 * - https://getbootstrap.com/docs/4.0/components/forms/, visited 20-10-23.
 * - https://getbootstrap.com/docs/4.0/components/input-group/, visited 20-10-23.
 *
 * @returns The contact page where users can fill out a form and submit it
 */

function ContactUs() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handlerChangeEvent_name = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
    //console.log("Name value is: " + name)
  };

  const handlerChangeEvent_email = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    //console.log("email value is: " + email)
  };

  const handlerChangeEvent_message = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setMessage(e.target.value);
    //console.log("message value is: " + message)
  };

  const submitForm = () => {
    //checks: are all input fields filled out?
    if (name === "" || email === "" || message === "") {
      alert("Venligst udfyld alle felter");
    } else {
      postContactForm();
    }
  };

  const postContactForm = () => {
    var object = {
      name: name,
      email: email,
      message: message,
      sentTime: getCorrectDateAndTime(), //necessary in case the timezone of the server is not same as client
    };

    axios
      .post("https://localhost:7083/api/Messages", object)
      .then((response) => {
        console.log(response.data);
        alert("Besked sendt");
        window.location.reload(); //reload page to clear form
      })
      .catch(function (error) {
        if (error.response) {
          // Error occured
          console.log(error.response.status);
          console.log(error.response.data.title);
          console.log(error.response.data);
          alert(
            "Der er sket en fejl. Tjek alle felter er udfyldt korrekt og prøv igen"
          );
        }
      });
  };

  return (
    <div>
      <div className="container-fluid ">
        <Helmet>
          <title>FindDyrlæge | Kontakt</title>
        </Helmet>
        <div className="row">
          <div className="col-md-2">
            <NavigationBar />
          </div>
          <div className="col-md-10">
            <div className="d-flex justify-content-between mb-4">
              <div />
              <div>
                <LoginModal />
              </div>
            </div>
            <div className="col-md-10">
              <div className="card">
                <div className="card-body">
                  <h2 className="card-title">Udfyld kontaktformularen</h2>
                  <form>
                    <div>
                      <label className="form-label">Navn</label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        required
                        placeholder="Indtast navn"
                        onChange={handlerChangeEvent_name}
                      />
                    </div>
                    <div>
                      <label className="form-label">E-mail</label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        required
                        placeholder="Indtast e-mail"
                        onChange={handlerChangeEvent_email}
                      />
                    </div>
                    <div>
                      <label className="form-label">Besked</label>
                      <textarea
                        className="form-control"
                        id="content"
                        rows={4}
                        required
                        placeholder="Indtast besked"
                        onChange={handlerChangeEvent_message}
                      ></textarea>
                    </div>
                    <div>
                      <h4>
                        Alternativt kan du kontakte os på e-mail:{" "}
                        <a href="mailto:finddyrlaege@gmail.com">
                          finddyrlaege@gmail.com
                        </a>
                      </h4>
                    </div>
                    <button
                      onClick={submitForm}
                      type="button"
                      className="btn btn-primary mt-2"
                    >
                      Send
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ContactUs;

import { useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
import axios from "axios";

/*
Utility functions to aid any component of the web app 

Inspiration: https://www.pluralsight.com/guides/importing-utility-functions-in-reactjs, visited 01-10-23
*/

/* Navigation function: Navigate to a specific route */
export function useNavigatePath(routePath: string) {
  const nav = useNavigate();

  const toRoutePath = () => {
    nav(routePath);
  };

  return toRoutePath;
}

/* Save the currently logged in user to the browser local storage */
export function saveUserToLocalStorage(object: object) {
  localStorage.removeItem("user"); //delete if any already exists, else do nothing
  localStorage.setItem("user", JSON.stringify(object));

  console.log("New user logged in");
}

/* Get a property from the currently logged in user, e.g. name or id */
export function getSavedUserPropertyFromLocalStorage(property: string) {
  var temp = localStorage.getItem("user");
  if (temp != null) {
    console.log(JSON.parse(temp));
    console.log(JSON.parse(temp)[property]);
    return JSON.parse(temp)[property];
  } else {
    return "";
  }
}

/* Gets the correct date and time from the timezone of the user in DK
Inspiration: https://moment.github.io/luxon/#/tour, visited 10-10-23 */
export function getCorrectDateAndTime() {
  const currentDateAndTimeLocal = DateTime.now()
    .setZone("Europe/Copenhagen")
    .toISO();
  console.log(currentDateAndTimeLocal);
  return currentDateAndTimeLocal;
}

/* Takes DateTime format like 2023-10-23T12:14:04.293 and converts it into 23-10-2023 (DD-MM-YYYY) */
export function convertDateToDkFormat(dateTime: string) {
  const convertedString =
    dateTime.substring(8, 10) +
    "-" +
    dateTime.substring(5, 7) +
    "-" +
    dateTime.substring(0, 4);

  return convertedString;
}

/* Takes a string and makes sure the first letter is capital letter */
export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

/* Checks if the user email already exists in the database. If it does, return true */
export function doesUserEmailExistInDatabase(email: string) {
  return axios
    .get("https://localhost:7083/api/Users/email/" + email)
    .then((response) => {
      console.log(response.data);
      console.log("User already exists");
      return true; //user already exists
    })
    .catch(function (error) {
      if (error.response) {
        // Error occured - e.g. http 404 user not found
        console.log(error.response.status);
        console.log("User does not already exist");
        return false; //user does not already exist
      }
    });
}

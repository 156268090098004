import React from "react";
import logo150 from "./../../resources/images/FD_logo_150x150.png";
import disclaimer from "./../../resources/images/disclaimer.png";
import "./style.scss";
import { useNavigatePath } from "../../util/utils";
import { useEffect, useState } from "react";

/*
Component for the navigation bar to be shown on pages. Allows for navigation through pages. Has conditional rendering for 3 outcomes: 
1. Not logged in
2. Logged in as private user
3. Logged in as business user. 

Inspiration:
- https://getbootstrap.com/docs/5.3/components/navbar/, visited 20-10-23.
- https://getbootstrap.com/docs/5.3/layout/containers/, visited 20-10-23.
- https://getbootstrap.com/docs/5.3/utilities/flex/, visited 20-10-23.
*/

const NavigationBar = () => {
  const toFrontpage = useNavigatePath("/");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isBusinessUser, setIsBusinessUser] = useState(false);

  useEffect(() => {
    //get the user from local storage to determine the conditional rendering
    const getUserFromLocalStorage = () => {
      var getUser = localStorage.getItem("user");
      if (getUser != null) {
        setIsLoggedIn(true); // if user != null then user is logged in
        setIsBusinessUser(JSON.parse(getUser).isBusinessUser);
      }
    };

    getUserFromLocalStorage();
  }, []);

  return (
    <div>
      <div className="navbar-custom">
        <img
          className="image150x150"
          onClick={toFrontpage}
          src={logo150}
          alt="logo"
        />
        <nav className="navbar navbar-expand bg-body-tertiary">
          <ul className="navbar-nav flex-column">
            {isLoggedIn ? ( // If user is logged in
              isBusinessUser ? ( // If user is a business user & logged in
                <li>
                  <a className="nav-link" href="/erhverv/hjem">
                    Hjem
                  </a>
                  <a className="nav-link" href="/erhverv/sager">
                    Se sager
                  </a>
                  <a className="nav-link" href="/erhverv/sager-historik">
                    Se historiske sager
                  </a>
                  <a className="nav-link" href="/erhverv/priser">
                    Klinikkens priser
                  </a>
                  <a className="nav-link" href="/erhverv/profil">
                    Klinikkens oplysninger
                  </a>
                  <a className="nav-link" href="/om-os">
                    Om os
                  </a>
                  <a className="nav-link" href="/kontakt">
                    Kontakt os
                  </a>
                </li>
              ) : (
                // If user is a private user & logged in
                <li>
                  <a className="nav-link" href="/privat/hjem">
                    Hjem
                  </a>
                  <a className="nav-link" href="/privat/opret-sag">
                    Søg dyrlæge
                  </a>
                  <a className="nav-link" href="/privat/mine-sager">
                    Mine sager
                  </a>
                  <a className="nav-link" href="/privat/profil">
                    Mine oplysninger
                  </a>
                  <a className="nav-link" href="/faq">
                    Ofte stillede spørgsmål
                  </a>
                  <a className="nav-link" href="/om-os">
                    Om os
                  </a>
                  <a className="nav-link" href="/kontakt">
                    Kontakt os
                  </a>
                </li>
              )
            ) : (
              // If user is not logged in
              <li>
                <a className="nav-link" href="/">
                  Hjem
                </a>
                <a className="nav-link" href="/referencer">
                  Hvad siger vores kunder?
                </a>
                <a className="nav-link" href="/faq">
                  Ofte stillede spørgsmål
                </a>
                <a className="nav-link" href="/erhverv">
                  Til klinikker
                </a>
                <a className="nav-link" href="/om-os">
                  Om os
                </a>
                <a className="nav-link" href="/kontakt">
                  Kontakt os
                </a>
              </li>
            )}
          </ul>
        </nav>
        <img src={disclaimer} alt="disclaimer notification" width="150px" />
      </div>
    </div>
  );
};

export default NavigationBar;

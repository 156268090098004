import React from "react";
import NavigationBar from "../../../components/navigation";
import { Helmet } from "react-helmet";
import Footer from "../../../components/footer";
import ImagesComponent from "./imageContainers";
import LoginModal from "../../../components/loginModal";

/**
 *
 * Inspiration: Bootstrap classes buildups inspired from documentation:
 * - https://getbootstrap.com/docs/5.3/, visited 20-10-23.
 *
 * @returns The page which is the frontpage of the website
 */

function FrontPage() {
  return (
    <div>
      <div className="container-fluid">
        <Helmet>
          <title>FindDyrlæge | Forside</title>
        </Helmet>
        <div className="row">
          <div className="col-md-2">
            <NavigationBar />
          </div>
          <div className="col-md-10">
            <div className="d-flex justify-content-between mb-4">
              <div />
              <div>
                <LoginModal />
              </div>
            </div>
            <ImagesComponent />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default FrontPage;

import React from "react";
import { Helmet } from "react-helmet";
import NavigationBar from "../../../components/navigation";
import Footer from "../../../components/footer";
import "./style.scss";
import LoginModal from "../../../components/loginModal";

/**
 *
 * Inspiration: Bootstrap classes buildups inspired from documentation:
 * - https://getbootstrap.com/docs/5.3/components/card/, visited 20-10-23.
 * - https://getbootstrap.com/docs/5.3/components/, visited 20-10-23.
 *
 * @returns The page with frequently asked questions for the users
 */

function Faq() {
  return (
    <div>
      <div className="container-fluid">
        <Helmet>
          <title>FindDyrlæge | FAQ</title>
        </Helmet>
        <div className="row">
          <div className="col-md-2">
            <NavigationBar />
          </div>
          <div className="col-md-10">
            <div className="d-flex justify-content-between mb-4">
              <div />
              <div>
                <LoginModal />
              </div>
            </div>
            <div className="col-md-10">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">Ofte stillede spørgsmål</h5>
                </div>
                <div className="card-body">
                  <h3 className="card-left">
                    Spørgsmål 1: Hvordan fungerer FindDyrlæge?
                  </h3>
                  <p className="card-text ">
                    Du opretter dig og indgiver en anmodning om en bestemt
                    ydelse på dit kæledyr. Herefter byder dyrlægerne i dit
                    nærområde ind på din opgave, og du kan acceptere eller
                    afvise tilbuddene.
                  </p>
                  <br />
                  <h3 className="card-left">
                    Spørgsmål 2: Koster det noget at bruge?
                  </h3>
                  <p className="card-text">
                    Tjenesten er 100 % omkostningsfri for dig at bruge.
                  </p>
                  <br />
                  <h3 className="card-left">
                    Spørgsmål 3: Hvorfor skal jeg bruge FindDyrlæge?
                  </h3>
                  <p className="card-text">
                    Du skal bruge FindDyrlæge fordi at vi kan hjælpe dig med at
                    finde den bedste dyrlæge nær dig til den bedste pris og
                    service. Det er godt for både dig, dit kæledyr og din
                    pengepung.
                  </p>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Faq;

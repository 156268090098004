import React from "react";
import Footer from "../../../components/footer";
import NavigationBar from "../../../components/navigation";
import { Helmet } from "react-helmet";
import {
  capitalizeFirstLetter,
  convertDateToDkFormat,
  getSavedUserPropertyFromLocalStorage,
} from "../../../util/utils";
import LoginModal from "../../../components/loginModal";
import axios from "axios";
import { useEffect, useState } from "react";
import { ITasks } from "../../../util/interfaces";

/**
 * Inspirations: Bootstrap classes buildups inspired from documentation:
 * - https://getbootstrap.com/docs/5.3/components/card/, visited 20-10-23.
 * - https://getbootstrap.com/docs/5.3/components/, visited 20-10-23.
 *
 *
 * @returns The page where the user can see their task history
 */

function UserTaskHistory() {
  const [myTasks, setMyTasks] = useState<ITasks[]>([]); //source on how to use state type: https://stackoverflow.com/questions/53650468/set-types-on-usestate-react-hook-with-typescript, visited 30-10-23.

  const getTasks_all = () => {
    //gets all of currently tasks of current user

    const url =
      "https://localhost:7083/api/Tasks/user/" +
      getSavedUserPropertyFromLocalStorage("id");
    axios
      .get(url)
      .then((response) => {
        console.log(response.data);
        setMyTasks(response.data);
      })
      .catch(function (error) {
        if (error.response) {
          // Error occured
          console.log(error.response.status);
          console.log(error.response.data.title);
          console.log(error.response.data);
          alert(
            "Der er sket en fejl. Tjek alle felter er udfyldt korrekt og prøv igen"
          );
        }
      });
  };

  useEffect(() => {
    //make sure getTasks is updated when page is loaded
    getTasks_all();
  }, []);

  return (
    <div>
      <div className="container-fluid">
        <Helmet>
          <title>FindDyrlæge | Sager</title>
        </Helmet>
        <div className="row">
          <div className="col-md-2">
            <NavigationBar />
          </div>
          <div className="col-md-10">
            <div className="d-flex justify-content-between mb-4">
              <div />
              <div>
                <LoginModal />
              </div>
            </div>
            {/* View conditional on the status of task: Oprettet, Tilbud sendt, Afsluttet */}
            <div className="col-md-10">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">Dine sager</h5>
                </div>
                <div className="card-body">
                  {/* Mapping of objects below inspiration from: https://github.com/Hildan94/GRP1ProjectFinal/blob/main/web/src/pages/quizzes.js, visited 20-10-23.  */}
                  <div>
                    <h3 className="card">Afventer</h3>
                    <div>
                      {/* eslint-disable-next-line array-callback-return*/}
                      {myTasks.map(function (d, idx) {
                        if (d.status === "Oprettet") {
                          return (
                            <ul key={idx}>
                              <h3
                                onClick={() => {
                                  window.location.href =
                                    "/privat/mine-sager/" +
                                    d.id; /* navigate to task id */
                                }}
                                className="card-left"
                              >
                                Oprettet:{" "}
                                {convertDateToDkFormat(d.creationDate)}. Dyr:{" "}
                                {d.animalName}. Ydelse: {d.wantedService}.
                                Status: {d.status}. Id: {d.id}
                              </h3>
                              <p className="card-text">
                                {d.descriptionFromVet
                                  ? d.descriptionFromVet
                                  : "Afventer tilbud fra dyrlæge"}
                              </p>
                              <br />
                            </ul>
                          );
                        }
                      })}
                    </div>
                    <br />
                  </div>
                  <div>
                    <h3 className="card">Tilbud modtaget</h3>
                    <div>
                      {/* eslint-disable-next-line array-callback-return*/}
                      {myTasks.map(function (d, idx) {
                        if (d.status === "Tilbud sendt") {
                          return (
                            <ul key={idx}>
                              <h3
                                onClick={() => {
                                  window.location.href =
                                    "/privat/mine-sager/" +
                                    d.id; /* navigate to task id */
                                }}
                                className="card-left"
                              >
                                Oprettet:{" "}
                                {convertDateToDkFormat(d.creationDate)}. Dyr:{" "}
                                {d.animalName}. Ydelse: {d.wantedService}.
                                Status: {d.status}. Id: {d.id}
                              </h3>
                              <p className="card-text">
                                {d.descriptionFromVet
                                  ? d.descriptionFromVet
                                  : "Afventer tilbud fra dyrlæge"}
                              </p>
                              <br />
                            </ul>
                          );
                        }
                      })}
                    </div>
                    <br />
                  </div>
                  <div>
                    <h3 className="card">Afsluttet</h3>
                    <div>
                      {/* eslint-disable-next-line array-callback-return*/}
                      {myTasks.map(function (d, idx) {
                        if (d.status === "Afsluttet") {
                          return (
                            <ul key={idx}>
                              <h3
                                onClick={() => {
                                  window.location.href =
                                    "/privat/mine-sager/" +
                                    d.id; /* navigate to task id */
                                }}
                                className="card-left"
                              >
                                Oprettet:{" "}
                                {convertDateToDkFormat(d.creationDate)}. Dyr:{" "}
                                {d.animalName}. Ydelse: {d.wantedService}.
                                Status:{" "}
                                {capitalizeFirstLetter(d.userAnswer || "")}. Id:{" "}
                                {d.id}
                              </h3>
                              <p className="card-text">
                                {d.descriptionFromVet
                                  ? d.descriptionFromVet
                                  : "Afventer tilbud fra dyrlæge"}
                              </p>
                              <br />
                            </ul>
                          );
                        }
                      })}
                    </div>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default UserTaskHistory;
